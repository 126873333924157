export const environment = {
  production: true,
  //Afiliaciones
  baseUrlAPi:                 'https://afiliaciones.api.focunti.com',
  apiUrlAfiliacion:           'https://afiliaciones.api.focunti.com/afiliacion',
  apiUrlAfiliacionDocumento:  'https://afiliaciones.api.focunti.com/afiliacion_documento/',

  //entidades
  apiUrlEntidad: 'https://entidad.api.focunti.com/entidad',
  apiUrlApp: 'https://admin-prod.focunti.com/app/',

  //cliente
  apiUrlClientes: 'https://clientes.api.focunti.com/cliente',

  //empleado/documento/beneficiario
  apiUrlEmpleados: 'https://empleados.api.focunti.com/empleado',
  apiUrlDocumentos: 'https://empleados.api.focunti.com/documento',
  apiUrlBeneficiarios: 'https://empleados.api.focunti.com/beneficiario/',

  mocks:false
};
