import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map, Observable, of, switchMap, tap, throwError} from 'rxjs';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {UserService} from 'app/core/user/user.service';
import {User} from '../user/user.types';
import {Api} from "@core/models/Api.model";
import {ResultResponseInterface} from "@shared/interfaces/result-response.interface";


@Injectable()
export class AuthService {
    private _authenticated: boolean = false;
    private user: User;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    /**
     * Setter & getter for access token
     */
    set permissionsToken(token: string) {
        localStorage.setItem('permissionsToken', token);
    }

    get permissionsToken(): string {
        return localStorage.getItem('permissionsToken') ?? '';
    }

    /**
     * Setter & getter for refresh token
     */
    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    private storeJwtTokens(tokens: { refresh: string; access: string; permissions: string; }): void {
        this.accessToken = tokens.access;
        this.permissionsToken = tokens.permissions;
        this.refreshToken = tokens.refresh;        
    }

    removeTokens(): void {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('permissionsToken');
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { username: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }


        return this._httpClient.post<ResultResponseInterface<{
            refresh: string;
            access: string;
            permissions: string;
        }>>(`${Api.urlLogin}`, credentials).pipe(
            map(resp => resp.objeto),
            switchMap((response) => {

                // Store the access token in the local storage
                this.storeJwtTokens(response)

                // Set the authenticated flag to true
                this._authenticated = true;

                this.user = {
                    id: '',
                    name: credentials.username,
                    email: '',
                    status: 'online',
                }

                // Store the user on the user service
                this._userService.user = this.user;
                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response.accessToken) {
                    this.accessToken = response.accessToken;
                    //VERIFICAR ESTA OPCION PORQUE DEBERIA TRAERSE PERSMISOS Y REFRESH
                    //this.permissionsToken = response.permissions;
                    //this.refreshToken = response.refresh_token;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Auto Sign in
     *
     * @param token
     */
    autoSignIn(autToken: string): Observable<any> {  
        const url = `${Api.url_auto_ingreso}/${autToken}`;     
        return this._httpClient.get<ResultResponseInterface<{
            refresh: string;
            access: string;
            permissions: string;
            email: string;
        }>>(url).pipe(
            map(resp => resp.objeto),
            switchMap((response) => {
                if(!AuthUtils.isTokenExpired(response.access)){
                    this.user = {
                        id: '',
                        name: '',
                        email: response.email,
                        status: 'online',
                    }
                    this.storeJwtTokens(response);
                    this._userService.user = this.user;
                    return of(response);
                }
                
                return of(response);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        this.removeTokens();

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(data: { name: string; last_name: string; user: string; email: string; password: string }): Observable<any> {
        return this._httpClient.post(`${Api.BaseUrlApp}create-user/`, data);
    }


    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        // if ( AuthUtils.isTokenExpired(this.accessToken) )
        // {
        //     return of(false);
        // }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    /**
     * Solicitud de token de actualización
     */
    public refreshTokenRequest(): Observable<any> {
        return this._httpClient.post(`/auth/token`, {
            accessToken: this.accessToken,
            refreshToken: this.refreshToken
        }, {
            headers: {refresh_token: this.refreshToken || ''}
        }).pipe(
            tap(resp => {
                this.storeJwtTokens(resp)
            })
        );
    }
    
    public refreshTokenRequestNew(): Observable<any> {
        return this._httpClient.post(Api.refreshPermissions, {
            refresh: this.refreshToken
        }).pipe(
            tap(resp => {
                resp = resp.objeto
                resp['refresh'] = this.refreshToken
                this.storeJwtTokens(resp)
            })
        );
    }

}
