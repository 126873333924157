import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class UtilPopupService {
    constructor() {}

    /**
     * Getter that returns a configured Swal instance.
     */
    get swal(): typeof Swal {
        return Swal.mixin({
            allowOutsideClick: false,
            allowEscapeKey: false,
            cancelButtonText: 'No',
            confirmButtonText: 'Si',
            showCancelButton: true,
            showCloseButton: true,
            customClass: {
                popup: 'bg-primary text-white min-w-1/3',
                title: 'mat-headline-3 font-normal text-lime',
                actions: 'flex flex-col flex-auto w-full px-8',
                confirmButton: 'w-full bg-white text-primary',
                cancelButton: 'w-full bg-lime text-primary',
            },
        });
    }

    get swalConfirmation(): typeof Swal {
        return Swal.mixin({
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: 'Confirmo',
            showCancelButton: false,
            showCloseButton: true,
            customClass: {
                popup: 'bg-primary text-white min-w-1/3',
                htmlContainer: 'mt-20',
                actions: 'flex flex-col flex-auto w-full px-8',
                confirmButton: 'w-full bg-[#F5BF0D] text-primary mb-14',
            },
        });
    }

    /**
     * Method to display a message on the screen using sweetalert2.
     * @param mensaje Message text, can be HTML.
     * @param tipo Message type 'warning' | 'error' | 'success' | 'info' | 'question'.
     * @param titulo Message title.
     * @param mostrarConfirmacion Indicates whether to display the confirmation button.
     * @returns typeof @Swal
     */
    mostrarMensaje(
        mensaje: string,
        tipo: SweetAlertIcon,
        titulo: string = '',
        mostrarConfirmacion: boolean = true,
    ) {
        const options: any = {
            title: titulo,
            html: mensaje,
            icon: tipo,
        };

        if (!mostrarConfirmacion) {
            // Add options related to the confirmation button
            options.showCancelButton = false;
            options.showCloseButton = true;
            options.confirmButtonText = 'Continuar';
        }

        return this.swal.fire(options);
    }

    mostrarMensajeConfirmacion(opts: {
        message?: string;
        confirmButtonText?: string;
    }) {
        const options: SweetAlertOptions = {
            html: opts.message || 'sin mensaje',
            confirmButtonText: opts.confirmButtonText || 'Confirmo',
        };

        return this.swalConfirmation.fire(options);
    }
}
