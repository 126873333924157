import * as moment from "moment";
import {Moment, unitOfTime} from "moment";

export class MomentService {
    addDays(date: Date, days: number) {
        return moment(date).add(days, 'days').toDate();
    }
    formatDate(date: Date) {
        return moment(date).format('YYYY-MM-DD');
    }

    formatDateDMY(date: string) {
        return moment(date).format('DD/MM/YYYY');
    }

    getMoment(date: string|Date, format?: string){
        return moment(date, format)
    }

    getDateFromString(date: string, format?: string){
        return this.getMoment(date, format).toDate()
    }

    getDifference(dateInit: Moment, dateEnd: Moment, unitOfTime: unitOfTime.Diff = 'd'){
        return dateInit.diff(dateEnd, unitOfTime)
    }

    formatDateWithTime(date: Date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    formatDateWithTimeAndSeconds(date: Date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    dateFormatWithTimeSecondsAndPeriod(date: Date) {
        return moment(date).format('DD/MM/YYYY HH:mm:ss a');
    }

    formatDateWithTimeAndSecondsAndMilliSeconds(date: Date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss.SSS');
    }

    formatDateWithTimeAndMilliSeconds(date: Date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss.SSS');
    }

    formatDateWithTimeAndSecondsAndMilliSecondsAndMicroSeconds(date: Date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss.SSSSSS');
    }
}
