import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@core/models/Api.model';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FiltroParametrosService {
    constructor(private http: HttpClient) {}

    /*
     * Retrieve control affiliation by ID
     * @param id - Affiliation ID
     * @returns Observable<any>
     */
    busquedaControlAfiliacionId(id: string): Observable<any> {
        return this.http.get<any>(`${Api.urlAfiliacion}/afiliacion/${id}`);
    }

    validarColaborador(
        nit: string,
        documento: string,
        fecha: string,
    ): Observable<{
        afiliacion: string;
        cliente: string;
        empleado: string;
        error: string;
        anulacion: boolean;
    }> {

        return this.http.get<{
            afiliacion: string;
            cliente: string;
            empleado: string;
            error: string;
            anulacion: boolean;
        }>(`${Api.urlAfiliacion}/validar_colaborador/${nit}/${documento}/${fecha}/`);
    }

    validarBeneficiario(
        nit: string,
        documento: string,
        fecha: string,
        benfiData: { numDocBenefi: string, entidades: string, tipoDocBenefi: string }
    ): Observable<{
        afiliacion: string;
        cliente: string;
        empleado: string;
        error: string;
        anulacion: boolean;
    }> {
        const { numDocBenefi, entidades, tipoDocBenefi} = benfiData        
        return this.http.get<{
            afiliacion: string;
            cliente: string;
            empleado: string;
            error: string;
            anulacion: boolean;
        }>(`${Api.urlAfiliacion}/validar_colaborador/${nit}/${documento}/${fecha}/${numDocBenefi}/${tipoDocBenefi}/${entidades}`);
    }

    /*
     * Perform control affiliation search
     * @param data - Search data
     * @param eps - EPS filter
     * @param afp - AFP filter
     * @param arl - ARL filter
     * @param ccf - CCF filter
     * @returns Observable<any>
     */
    busquedaControlAfiliacion(
        data: any,
        eps: boolean,
        afp: boolean,
        arl: boolean,
        ccf: boolean
    ): Observable<any> {
        // Construct the route based on filters
        const ruta = `${Api.urlAfiliacion}/afiliacion/?eps=${eps}&ccf=${ccf}&arl=${arl}&afp=${afp}`;
        // Perform a POST request with the search data
        return this.http.post<any>(ruta, data);
    }

    /*
     * Retrieve companies
     * @returns Observable<any>
     */
    getEmpresas(): Observable<any> {
        return this.http
            .get<any>(`${Api.urlCliente}/cliente_basic/get_by_params/`)
            .pipe(map((resp) => resp.objeto));
    }

    /*
     * Retrieve complete parameters
     * @returns Observable<any>
     */
    getParametrosCompleto(): Observable<any> {
        return this.http.get<any>(`${Api.url_parametro}/`);
    }

    /*
     * Retrieve state parameters
     * @returns Observable<any>
     */
    getParametrosEstados(): Observable<any> {
        return this.http.get<any>(`${Api.url_parametro}/estados`);
    }

    /*
     * Retrieve beneficiary state parameters
     * @returns Observable<any>
     */
    getParametrosEstadosBeneficiario(): Observable<any> {
        return this.http.get<any>(`${Api.url_parametro}/estado_beneficiario`);
    }

    /*
     * Retrieve recent days
     * @returns Observable<any>
     */
    getDiasRecientes(): Observable<any> {
        return this.http.get<any>(`${Api.url_parametro}/dias_recientes/`);
    }
}
