import { environment } from '@env/environment.prod';

export class Api {

    public static readonly BaseUrlAfiliacion = environment.baseUrlAPi;
    public static readonly BaseUrl = environment.baseUrlAPi;
    public static readonly BaseUrlApp = environment.apiUrlApp;
    public static readonly BaseUrlBeneficiarios = environment.apiUrlBeneficiarios;

    //Login desde la ruta apiUrlApp: 'https://admin-prod.focunti.com/app/',
    public static readonly urlLogin = `${Api.BaseUrlApp}token/`;
    public static readonly comboBox = `${Api.BaseUrl}api.combobox`;
    public static readonly url_parametro = `${Api.BaseUrlApp}parametro`;
    public static readonly urlAfiliacion = environment.apiUrlAfiliacion;
    public static readonly url_departamentos = `${Api.BaseUrlApp}departments/`;
    public static readonly url_countries = `${Api.BaseUrlApp}countries/`;
    public static readonly url_ciudades: string = `${Api.BaseUrlApp}cities`;
    public static readonly urlEntidad = environment.apiUrlEntidad;

    //Este llama a las empresas desde apiUrlClientes: 'https://clientes.api.focunti.com/cliente/',
    public static readonly urlCliente = environment.apiUrlClientes;
    public static readonly urlDocumento = environment.apiUrlDocumentos;
    public static readonly urlDocumentoAnulacion = environment.baseUrlAPi+'/afiliacion_documento';
    public static readonly urlDocumentoEmpleado = environment.apiUrlEmpleados+'/empleado_persona_documento';
    public static readonly urlDocumentoBeneficiarios = environment.apiUrlEmpleados+'/beneficiario_persona_documento';
    public static readonly crear_afiliacion_individual: string = environment.apiUrlEmpleados+'/crear_afiliacion_individual';

    //Estas url no tienen match en los servicios de desarrollo
    public static readonly urlAfiliacionDocumento = environment.apiUrlAfiliacionDocumento;
    public static readonly urlEmpleado = environment.apiUrlEmpleados;
    public static readonly urlBeneficiarios = environment.apiUrlBeneficiarios;
    public static readonly urlDocumentoPersonas = environment.apiUrlDocumentos;

    //Verificación de estas rutas que generaron error en la build
    public static readonly url_auto_ingreso: string = `${this.BaseUrlApp}auto_sign`;
    public static readonly url_consulta_firma_documentos: string = `${this.urlAfiliacion}/afiliacion/firma/documentos`;
    public static readonly url_firmar_documentos: string = `${this.urlAfiliacion}/afiliacion/firma/digital`;
    public static readonly url_cargue_firmar_documentos: string = `${this.url_firmar_documentos}/empleado`;

    //Ruta descarga documento afiliación
    public static readonly urlDocumentoResultadoTables: string = `${this.BaseUrlAfiliacion}/afiliacion/download/document/`;

    //Ruta para actualizar token
    public static readonly refreshPermissions: string = `${Api.BaseUrlApp}/refresh_permissions/`;

}

